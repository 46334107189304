export default {
  title: {
    id: 'checkout_services_bouygues_error_title',
    defaultMessage: 'Something went wrong on our end.',
  },
  message: {
    id: 'checkout_services_bouygues_error_message',
    defaultMessage:
      'Sorry, there’s been a hiccup in our system. Please try again or come back later.',
  },
  tryAgainButton: {
    id: 'checkout_services_bouygues_error_try_again_button',
    defaultMessage: 'Try again',
  },
  backToHomeButton: {
    id: 'checkout_services_bouygues_error_back_to_home_button',
    defaultMessage: 'Continue shopping',
  },
  illustrationAlternativeText: {
    id: 'checkout_services_bouygues_error_illustration_alternative_text',
    defaultMessage: 'An error occured',
  },
}
