<template>
  <div class="flex flex-col gap-32 md:flex-row">
    <div class="space-y-32 md:w-1/2">
      <h2 class="heading-1">{{ i18n(translations.title) }}</h2>

      <div class="space-y-16">
        <p>{{ i18n(translations.message) }}</p>

        <div class="flex flex-col justify-end gap-16 md:flex-row-reverse">
          <RevButton
            full-width="responsive"
            variant="primary"
            @click="emit('try-again')"
          >
            {{ i18n(translations.tryAgainButton) }}
          </RevButton>
          <RevButton
            full-width="responsive"
            variant="secondary"
            @click="router.push({ name: HOME })"
          >
            {{ i18n(translations.backToHomeButton) }}
          </RevButton>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center md:w-1/2">
      <RevIllustration
        :alt="i18n(translations.illustrationAlternativeText)"
        class="py-60"
        :height="176"
        src="/img/bouygues/error.svg"
        :width="191"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'

import { HOME } from '~/scopes/home/route-names'

import translations from './BouyguesError.translations'

const router = useRouter()
const emit = defineEmits(['try-again'])
const i18n = useI18n()
</script>
